<template>
  <b-row>
    <b-col cols="12">
      <div v-if="loading">
        <b-button class="mb-3" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
        <div class="text-center">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2 h6">Loading...</p>
        </div>
      </div>
      <div v-if="!loading && values">
        <div class="d-flex flex-row mb-3">
          <b-button class="mr-1" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
          <b-button class="mr-1" :href="csvLink" variant="light"><fa-icon class="mr-1" icon="download" /> CSV</b-button>
          <div class="justify-content-end d-flex flex-row flex-fill">
            <b-input-group style="max-width: 700px;">
              <b-form-datepicker v-model="start" />
              <b-input-group-text class="border-left-0 border-right-0"> - </b-input-group-text>
              <b-form-datepicker v-model="end" />
            </b-input-group>
          </div>
        </div>
        <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="values">
          <template #cell(name)="{ item }">
            {{ item.property ? item.property.name : item.customer.name }}
          </template>
          <template #cell(revenue)="{ item }">
            {{ item.revenue ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(item.revenue)) : 'N/A' }}
          </template>
          <template #cell(avg_planned)="{ item }">
            {{ item.avg_planned ? `${item.avg_planned} mins` : 'N/A' }}
          </template>
          <template #cell(avg_duration)="{ item }">
            {{ item.avg_duration ? `${item.avg_duration} mins` : 'N/A' }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      start: this.moment().add(-1, 'month').startOf('month').toDate(),
      end: this.moment().add(-1, 'month').endOf('month').toDate(),
      loading: false,
      values: {},
      fields: [
        { key: 'name', text: 'Name' },
        { key: 'jobs', text: 'Jobs' },
        { key: 'avg_planned', text: 'Avg. Planned Duration' },
        { key: 'avg_duration', text: 'Actual Duration' },
        { key: 'revenue', text: 'Revenue' },
      ],
    };
  },
  async mounted() {
    // Load Report
    await this.load();
  },
  computed: {
    csvLink() {
      return `/api/reports/jobs.csv?start=${this.moment(this.start).format('YYYY-MM-DD')}&end=${this.moment(this.end).format('YYYY-MM-DD')}`;
    }
  },
  methods: {
    moment,
    async load() {
      this.loading = true;

      const response = await window.apiFetch(`/reports/jobs?start=${this.moment(this.start).format('YYYY-MM-DD')}&end=${this.moment(this.end).format('YYYY-MM-DD')}`);
      this.values = await response.json();

      this.loading = false;
    }
  },
  watch: {
    start() {
      return this.load();
    },
    end() {
      return this.load();
    },
  }
}
</script>
